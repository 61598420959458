import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path1/apps/web/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/vercel/path1/apps/web/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/apps/web/src/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogIdentityProvider"] */ "/vercel/path1/apps/web/src/providers/post-hog-identity-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogPageView"] */ "/vercel/path1/apps/web/src/providers/post-hog-page-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CsPostHogProvider"] */ "/vercel/path1/apps/web/src/providers/post-hog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogTeamIdProvider"] */ "/vercel/path1/apps/web/src/providers/post-hog-team-id-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SentryIdentityProvider"] */ "/vercel/path1/apps/web/src/providers/sentry-identity-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrpcProvider"] */ "/vercel/path1/apps/web/src/providers/trpc-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path1/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ViewTransitions"] */ "/vercel/path1/node_modules/next-view-transitions/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Playfair_Display\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"variable\",\"variable\":\"--font-playfair-display\",\"display\":\"swap\"}],\"variableName\":\"playfairDisplay\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"variable\",\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/vercel/path1/node_modules/nuqs/dist/adapters/next/app.js");
